<script lang="ts">
	import { browser } from '$app/env';
	import FullscreenCountdown from '$lib/components/app/FullscreenCountdown.svelte';
	import Footer from '$lib/components/app/Footer.svelte';
	import Navbar from '$lib/components/app/Navbar.svelte';
	import Notifications from '$lib/components/app/Notifications.svelte';
	import Preloader from '$lib/components/app/Preloader.svelte';
	import WalletAccess from '$lib/components/app/WalletAccess.svelte';
	import { wallet } from '$lib/wallet';
	import { launchUnixTimeMillis } from '$lib/config';

	let now: number = Date.now();
	setInterval(() => {
		now = Date.now();
	}, 750);
</script>

{#if launchUnixTimeMillis && launchUnixTimeMillis > now}
	<FullscreenCountdown endUnixTimeMillis={launchUnixTimeMillis} />
{:else}
	<WalletAccess>
		<Preloader loading={!browser || $wallet.connecting}>
			<Notifications />
			<Navbar />
			<slot />
			<Footer />
		</Preloader>
	</WalletAccess>
{/if}
