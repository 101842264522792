<script lang="ts">
	export let name: string;
	export let onClick: () => void;

	type WalletInfo = {
		id: string;
		name: string;
		image: string;
		borderColor: string;
		backgroundColor: string;
	};

	const baseImagePath = '/images/wallets/';
	const walletsInfo: { [id: string]: WalletInfo } = {
		metamask: {
			id: 'metamask',
			name: 'Metamask',
			image: baseImagePath + 'metamask.svg',
			borderColor: 'border-orange-500',
			backgroundColor: 'bg-orange-500'
		},
		walletconnect: {
            id: 'walletconnect',
            name: 'Wallet Connect',
            image: baseImagePath + 'walletconnect.svg',
            borderColor: 'border-blue-500',
            backgroundColor: 'bg-blue-500'
        },
	};

	const wallet = walletsInfo[name.toLowerCase()] || {
		id: 'generic-wallet',
		name: 'Generic Wallet',
		image: baseImagePath + 'web3-default.png',
		borderColor: 'border-gray-500',
		backgroundColor: 'bg-gray-500'
	};
</script>

<div
	class="flex w-full cursor-pointer {wallet.backgroundColor} bg-opacity-30 {wallet.borderColor} border-2 rounded-box place-items-center justify-left py-2 px-4"
	on:click={onClick}
>
	<img
		class="p-0 mx-2 h-8 w-8 object-contain"
		alt={`Login with ${wallet.name}`}
		src={wallet.image}
	/>
	<span class="ml-2">{wallet.name}</span>
</div>
