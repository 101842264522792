<script>
	import { wallet, flow, chain, balance } from '$lib/wallet';
</script>

<div class="navbar bg-base-100">
	<div class="navbar-start">
		<div class="dropdown">
			<label tabindex="0" class="btn btn-ghost lg:hidden">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					class="h-5 w-5"
					fill="none"
					viewBox="0 0 24 24"
					stroke="currentColor"
					><path
						stroke-linecap="round"
						stroke-linejoin="round"
						stroke-width="2"
						d="M4 6h16M4 12h8m-8 6h16"
					/></svg
				>
			</label>
			<ul
				tabindex="0"
				class="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52"
			>
				<li><a href="/app">Search</a></li>
				{#if $wallet.address}
					<li><a href="/app/address/{$wallet.address}">My Account</a></li>
				{/if}
			</ul>
		</div>
		<a class="btn btn-ghost" href="/"
			><img width="100" src="/images/kuname-logo-text-white-67.svg" /></a
		>
	</div>
	<div class="navbar-center hidden lg:flex">
		<ul class="menu menu-horizontal p-0">
			<li><a href="/app">Search</a></li>
			{#if $wallet.address}
				<li><a href="/app/address/{$wallet.address}">My Account</a></li>
			{/if}
		</ul>
	</div>
	<div class="navbar-end">
		{#if $wallet.state !== 'Ready'}
			<button class="btn m-1" on:click={() => flow.connect()}>Connect Wallet</button>
		{:else}
			<div class="dropdown dropdown-hover dropdown-end">
				<label tabindex="0" class="btn normal-case m-1"
					>{$wallet.address.slice(0, 6)}...{$wallet.address.slice(
						$wallet.address.length - 4
					)}</label
				>
				<div
					tabindex="0"
					class="dropdown-content card card-compact w-64 p-2 shadow-md bg-base-200 text-primary-content"
				>
					<div class="card-body">
						<h3 class="card-title">Wallet</h3>
						<div class="rounded-box overflow-x-auto">
							<table class="table w-full">
								<tbody>
									<tr>
										<td>KCS</td>
										<td>{$balance.amount ?? '-'}</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
					<div class="card-actions justify-end">
						<button class="btn btn-sm btn-base-300" on:click={() => wallet.disconnect()}
							>Disconnect</button
						>
					</div>
				</div>
			</div>
		{/if}
	</div>
</div>
