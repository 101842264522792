<script lang="ts">
	import { base } from '$app/paths';
</script>

<a label="Download Metamask" href="https://metamask.io/download.html" target="_blank" class="m-4">
	<div
		class="flex bg-orange-500 bg-opacity-30 border-orange-500 border-2 rounded-box place-items-center justify-left py-2 px-4"
	>
		<img
			class="p-0 mx-2 h-8 w-8 object-contain"
			alt="Download Metamask"
			src="/images/wallets/metamask.svg"
		/>
		<span class="ml-2">Download Metamask</span>
	</div>
</a>
