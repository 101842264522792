<script lang="ts">
	import { chainId, chainName, fallbackProviderOrUrl, webWalletURL } from '$lib/config';
	import { chainInfo } from '$lib/networks';
	import { wallet, builtin, chain, flow } from '$lib/wallet';
	import MetamaskDownload from './MetamaskDownload.svelte';
	import ModalDialog from './ModalDialog.svelte';
	import WalletOption from './WalletOption.svelte';

	export let title = '';

	let options: string[] = [];

	const wrongNetworkModalId = 'wrong-network-modal';
	let wrongNetworkModal: ModalDialog;

	const walletAccessModalId = 'wallet-access-modal';
	let walletAccessModal: ModalDialog;

	let isSwitchingChain: boolean;

	function acknowledgeNewGenesis() {
		chain.acknowledgeNewGenesisHash();
	}

	async function switchChain() {
		isSwitchingChain = true;
		let blockExplorerUrls: string[] | undefined;
		const explorerTXURL = import.meta.env.VITE_BLOCK_EXPLORER_TRANSACTION as string;
		if (explorerTXURL) {
			blockExplorerUrls.push(explorerTXURL.slice(0, explorerTXURL.length - 2));
		}
		const rpcUrls = [];
		if (webWalletURL) {
			rpcUrls.push(webWalletURL);
		}
		if (typeof fallbackProviderOrUrl === 'string') {
			rpcUrls.push(fallbackProviderOrUrl);
		}
		await chain.switchChain(chainId, {
			chainName,
			rpcUrls,
			blockExplorerUrls,
			nativeCurrency: chainInfo[chainId].nativeCurrency
		});
		isSwitchingChain = false;
	}

	$: options = $wallet.options.filter(
		(v) => v !== 'builtin' || ($builtin.available && $builtin.state === 'Ready')
	);

	$: walletAccessModal?.setVisibility($flow.inProgress);

	$: wrongNetworkModal?.setVisibility($chain.notSupported);

	$: builtinNeedInstallation =
		$wallet.options.filter((v) => v === 'builtin' && !$builtin.available).length > 0;

	//$: executionError = $flow.executionError as any;

	$: {
		if ($chain.error) {
			chain.acknowledgeError();
			flow.cancel();
			console.log('chain acknowledged error');
		} else if ($wallet.error) {
			wallet.acknowledgeError();
			flow.cancel();
			console.log('wallet acknowledged error');
		}
		//console.log('flow cancelled');
	}
</script>

<slot />

<!-- {#if $chain.state === 'Idle' &#38;&#38; !$chain.connecting &#38;&#38; $fallback.state === 'Idle' &#38;&#38; !$fallback.connecting} -->
<!-- 	&#60;&#33;&#45;&#45; Not Used Here: No need of node connection &#45;&#45;&#62; -->
<!-- 	&#60;&#33;&#45;&#45; <div -->
<!--     class="w-full flex items-center justify-center fixed top-0 pointer-events-none" -->
<!--     style="z-index: 5;"> -->
<!--     <p -->
<!--       class="w-64 text-center rounded-bl-xl rounded-br-xl text-gray-200 bg-pink-600 p-1"> -->
<!--       Please Connect. -->
<!--     </p> -->
<!--   </div> &#45;&#45;&#62; -->
<!-- {:else if $chain.state === 'Idle' &#38;&#38; !$chain.connecting &#38;&#38; $fallback.error} -->
<!-- 	&#60;&#33;&#45;&#45; Not Used Here: No need of node connection, we should check thegraph connection instead &#45;&#45;&#62; -->
<!-- 	&#60;&#33;&#45;&#45; <div -->
<!--     class="w-full flex items-center justify-center fixed top-0 pointer-events-none" -->
<!--     style="z-index: 5;"> -->
<!--     <p -->
<!--       class="w-64 text-center rounded-bl-xl rounded-br-xl text-gray-200 bg-pink-600 p-1"> -->
<!--       Network Issues, Please Connect. -->
<!--     </p> -->
<!--   </div> &#45;&#45;&#62; -->
<!-- {:else if $chain.notSupported} -->
<!-- 	<div class="w-full flex items-center justify-center fixed top-0" style="z-index: 5;"> -->
<!-- 		<p class="w-64 text-center rounded-bl-xl rounded-br-xl text-gray-200 bg-pink-600 p-1"> -->
<!-- 			Wrong network, switch to -->
<!-- 			{chainName} -->
<!-- 			<button class="border-2 border-white p-2" on:click={switchChain}>OK</button> -->
<!-- 		</p> -->
<!-- 	</div> -->
<!-- {:else if $chain.genesisChanged} -->
<!-- 	<div class="w-full flex items-center justify-center fixed top-0" style="z-index: 5;"> -->
<!-- 		<p class="w-64 text-center rounded-bl-xl rounded-br-xl text-gray-200 bg-red-500 p-1"> -->
<!-- 			chain reset detected! Metamask need to have its account reset! <button -->
<!-- 				class="border-2 border-white p-2" -->
<!-- 				on:click={acknowledgeNewGenesis}>OK</button -->
<!-- 			> -->
<!-- 		</p> -->
<!-- 	</div> -->
<!-- {/if} -->

<ModalDialog
	modalId={wrongNetworkModalId}
	bind:this={wrongNetworkModal}
	{title}
	showCloseButton={false}
	widthFitContent={true}
	closeIfClickOutside={false}
>
	Please switch to {chainName}
	<button
		class="btn btn-xs ml-3"
		label="Switch Chain"
		on:click={switchChain}
		disabled={isSwitchingChain}>Switch</button
	>
</ModalDialog>

<ModalDialog
	modalId={walletAccessModalId}
	bind:this={walletAccessModal}
	{title}
	showCloseButton={false}
	widthFitContent={true}
	closeIfClickOutside={!$wallet.connecting}
	on:close={() => {
		console.log('closed');
		flow.cancel();
	}}
>
	{#if $wallet.state === 'Idle'}
		{#if $wallet.loadingModule}
			Loading module:
			{$wallet.selected}
		{:else if $wallet.connecting}
			Connecting to wallet...
		{:else}
			<div class="p-2">
				<div class="text-center">
					<p class="text-lg font-medium">Please choose a wallet</p>
				</div>
				<div class="justify-center mt-4 pb-3">
					{#each options as option}
						<div class="mt-2">
							<WalletOption
								name={option === 'builtin' ? $builtin.vendor : option}
								onClick={() => wallet.connect(option)}
							/>
						</div>
					{/each}
				</div>
				{#if builtinNeedInstallation}
					<div class="divider">OR</div>
					<MetamaskDownload />
				{/if}
			</div>
		{/if}
	{:else if $wallet.state === 'Locked'}
		{#if $wallet.unlocking}
			Please accept to connect your wallet to the application.
		{:else}
			Please unlock your wallet to continue
			<button
				class="btn btn-xs ml-3"
				label="Unlock Wallet"
				on:click={() => wallet.unlock()}
				disabled={isSwitchingChain}>Unlock</button
			>
		{/if}
	{:else if $chain.state === 'Idle'}
		{#if $chain.connecting}
			Connecting...
		{:else if $chain.error}
			<div class="text-center">
				<p>{$chain.error?.message || '' + $chain.error}</p>
				<button class="btn btn-xs ml-3" label="OK" on:click={() => flow.cancel()}>OK</button>
			</div>
		{/if}
	{:else if $chain.state === 'Connected' && $chain.loadingData}
		Loading contracts...
		<!-- {:else if $chain.state === 'Connected'} -->
		<!-- 	{#if $chain.loadingData} -->
		<!-- 		Loading contracts... -->
		<!-- 	{:else if $chain.notSupported} -->
		<!-- 		Please switch to {chainName} -->
		<!-- 		&#60;&#33;&#45;&#45; ({$chain.chainId}) &#45;&#45;&#62; -->
		<!-- 		<button label="Switch Chain" on:click={switchChain}>Switch</button> -->
		<!-- 	{/if} -->
		<!-- {:else if executionError} -->
		<!-- 	<div class="text-center"> -->
		<!-- 		<p> -->
		<!-- 			{#if executionError.code === 4001} -->
		<!-- 				You rejected the request -->
		<!-- 			{:else if executionError.message}{executionError.message}{:else}Error: {executionError}{/if} -->
		<!-- 		</p> -->
		<!-- 		<button class="mt-4" label="Retry" on:click={() => flow.retry()}>Retry</button> -->
		<!-- 	</div> -->
	{:else if $wallet.pendingUserConfirmation}
		{#if $wallet.pendingUserConfirmation[0] === 'transaction'}
			Please accept transaction...
		{:else if $wallet.pendingUserConfirmation[0] === 'signature'}
			Please accept signature...
		{:else}Please accept request...{/if}
	{:else if $wallet.state === 'Ready'}
		Please wait...
	{:else}
		<div class="text-center">
			<p>Flow aborted {$wallet.state}</p>
			<button class="btn btn-xs ml-3" label="OK" on:click={() => flow.cancel()}>OK</button>
		</div>
	{/if}
</ModalDialog>
