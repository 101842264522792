<script lang="ts">
	import Alert from '$lib/components/app/Alert.svelte';
	import { notifications } from '$lib/notifications';
	import { flip } from 'svelte/animate';
	import { fly } from 'svelte/transition';
</script>

<div class="notifications notifications-top-center md:notifications-top-right">
	{#each $notifications as notification (notification.id)}
		<div animate:flip class="toast" transition:fly={{ y: 30 }}>
			<Alert text={notification.text} title={notification.title} type={notification.type} />
		</div>
	{/each}
</div>

<style>
	.toast {
		flex: 0 0 auto;
		margin-bottom: 10px;
	}
</style>
