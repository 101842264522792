<script lang="ts">
	import { fade } from 'svelte/transition';
	import Countdown from '../Countdown.svelte';

	export let endUnixTimeMillis: number;
</script>

<div
	class="fixed z-[9999999] w-full h-full top-0 left-0 flex justify-center items-center"
	out:fade={{ duration: 1000 }}
>
	<div class="items-center justify-center text-center">
		<div class="flex items-center justify-center text-center">
			<img src="/images/knd-logo-rainbow.png" alt="KuName Logo" class="w-80 sm:w-96" />
		</div>
		<p class="text-center text-xl mt-5">The #1 name service provider on KCC.</p>
		<p class="text-center text-3xl mt-16 text-primary-content">We are coming.</p>
		<div class="mt-10">
			<Countdown endUnixTimeMillis={endUnixTimeMillis} />
		</div>
	</div>
</div>
