<script lang="ts">
	import { fade } from 'svelte/transition';

	import LoadingSpinner from './LoadingSpinner.svelte';

	export let loading: boolean;
</script>

{#if loading}
	<div class="w-full h-full fixed block top-0 left-0 bg-neutral opacity-95  z-50" out:fade>
		<div class="w-full h-full flex items-center justify-center" out:fade>
			<LoadingSpinner />
		</div>
	</div>
{/if}

<slot />
