<script lang="ts">
	export let startUnixTimeMillis: number = Date.now();
	export let endUnixTimeMillis: number;

	let diffTime = Math.abs(startUnixTimeMillis - endUnixTimeMillis);
	let days: number;
	let hours: number;
	let minutes: number;
	let seconds: number;

	$: {
		days = diffTime / (24 * 60 * 60 * 1000);
		hours = (days % 1) * 24;
		minutes = (hours % 1) * 60;
		seconds = (minutes % 1) * 60;

		[days, hours, minutes, seconds] = [
			Math.floor(days),
			Math.floor(hours),
			Math.floor(minutes),
			Math.floor(seconds)
		];
	}

	setInterval(() => {
		if (diffTime > 0) {
			diffTime -= 1000;
		}
	}, 1000);
</script>

<div class="grid grid-flow-col gap-5 text-center auto-cols-max justify-center">
	<div class="flex flex-col">
		<span class="countdown font-mono text-5xl">
			<span style="--value:{days};" />
		</span>
		days
	</div>
	<div class="flex flex-col">
		<span class="countdown font-mono text-5xl">
			<span style="--value:{hours};" />
		</span>
		hours
	</div>
	<div class="flex flex-col">
		<span class="countdown font-mono text-5xl">
			<span style="--value:{minutes};" />
		</span>
		min
	</div>
	<div class="flex flex-col">
		<span class="countdown font-mono text-5xl">
			<span style="--value:{seconds};" />
		</span>
		sec
	</div>
</div>
